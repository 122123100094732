<template>
	<div class="home">
		<div id="page-title" class="page-title has-bg">
			<div class="bg-cover" data-paroller="true" data-paroller-factor="0.5" data-paroller-factor-xs="0.2"
				style="background: url(../assets/img/cover/cover-4.jpg) center 0px / cover no-repeat"></div>
			<div class="container">
				<h1>TESTING</h1>
				<p>Blog Concept Front End Page</p>
			</div>
		</div>
		<!-- begin #content -->
		<div id="content" class="content">
			<!-- begin container -->
			<div class="container">
				<!-- begin row -->
				<div class="row row-space-30">
					<!-- begin col-9 -->
					<div class="col-lg-9">
						
						<PostList />

						<div class="section-container">
							<!-- begin pagination -->
							<div class="pagination-container">
								<ul class="pagination justify-content-center">
									<li class="page-item disabled"><a class="page-link" href="javascript:;">Prev</a>
									</li>
									<li class="page-item active"><a class="page-link" href="javascript:;">1</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
									<li class="page-item"><span class="text">...</span></li>
									<li class="page-item"><a class="page-link" href="javascript:;">1797</a></li>
									<li class="page-item"><a class="page-link" href="javascript:;">Next</a></li>
								</ul>
							</div>
							<!-- end pagination -->
						</div>

					</div>
					<!-- end col-9 -->
					<Sidebar />
				</div>
				<!-- end row -->
			</div>
			<!-- end container -->
		</div>
		<!-- end #content -->
	</div>
</template>

<script>
import PostList from '../components/posts/PostsList.vue';
import Sidebar from '../components/layout/Sidebar.vue';

export default {
	name: 'HomeView',
	components: {
		PostList,
		Sidebar
	}
}
</script>

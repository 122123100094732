import { projectAuth } from '@/firebase/config';
import { ref } from 'vue';

const error = ref(null);
const isPending = ref(false);

const logout = async (email, password) => {
    error.value = null;
    isPending.value = true;

    try {
        await projectAuth.signOut();
        isPending.value = false;
    } catch (err) {
        console.log(err.message);
        error.value = "Error Logging out";
        isPending.value = false;
    }
}

const uselogout = () => {
    return { error, logout, isPending }
}

export default uselogout;
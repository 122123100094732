<template>
  <Navbar />
  <div class="content">
    <router-view/>
  </div>
  <Footer />
  </template>
  
  <script>
  import Navbar from './components/layout/Navbar.vue';
  import Footer from './components/layout/Footer.vue';
  
  export default ({
    components: { Navbar, Footer }
  })
  </script>
  
  
  <style>
  .content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 20px;
  }
  </style>
  
<template>
    <!-- begin #header -->
    <div id="header" class="header navbar navbar-default navbar-expand-lg navbar-fixed-top">
        <nav>
            <img src="@/assets/GingerPrimeLogoPurple.png">
            <h1><router-link :to="{ name: 'home' }">GINGER PRIME</router-link>
            </h1>
        </nav>
        <!-- begin container -->
        <div class="container">
            <div class="links">
                <div v-if="user">
                    <button @click="handleClick">Logout</button>
                </div>
                <div v-else>
                    <router-link :to="{ name: 'Signup' }" class="btn">Signup</router-link>
                    <router-link :to="{ name: 'Login' }" class="btn">Login</router-link>
                </div>
            </div>
            <!-- begin navbar-toggle -->
            <!-- <button type="button" class="navbar-toggle collapsed" data-bs-toggle="collapse"
                data-bs-target="#header-navbar">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button> -->
            <!-- end navbar-toggle -->
            <!-- begin navbar-collapse -->
            <!-- <div class="collapse navbar-collapse" id="header-navbar">
                <ul class="nav navbar-nav navbar-right">
                    <li class="dropdown">
                        <a href="#" data-bs-toggle="dropdown">HOME <b class="caret"></b></a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="index_transparent_header.html">Page with Transparent
                                Header</a>
                            <a class="dropdown-item" href="index_inverse_header.html">Page with Inverse Header</a>
                            <a class="dropdown-item" href="index.html">Default Header</a>
                        </div>
                    </li>
                    <li class="dropdown">
                        <a href="#" data-bs-toggle="dropdown">POSTS <b class="caret"></b></a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="post_grid.html">Page with Grid View Blog Post</a>
                            <a class="dropdown-item" href="post_without_sidebar.html">Page without Sidebar</a>
                        </div>
                    </li>
                    <li><a href="about_me.html">ABOUT ME</a></li>
                    <li><a href="contact_us.html">CONTACT US</a></li>
                    <li><a
                            href="https://wrapbootstrap.com/theme/color-admin-admin-template-front-end-WB0N89JMK">PURCHASE</a>
                    </li>
                </ul>
            </div> -->
            <!-- end navbar-collapse -->
        </div>
        <!-- end container -->
    </div>
    <!-- end #header -->
</template>

<script>
import useLogout from '@/composables/useLogout';
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router';

export default {
    setup() {
        const { logout } = useLogout();
        const router = useRouter();
        const { user } = getUser();

        const handleClick = async () => {
            await logout();
            console.log('user logged out');
            router.push({ name: 'Login' });
        }

        return { handleClick, user }
    }
}
</script>

<style scoped>
.navbar {
    padding: 16px 10px;
    margin-bottom: 60px;
    background: white;
}

nav {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

nav h1 {
    margin-left: 20px;
}

nav .links {
    margin-left: auto;
}

nav .links a,
button {
    margin-left: 16px;
    font-size: 14px;
}

nav img {
    max-height: 60px;
}
</style>
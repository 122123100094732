import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyDH4HGOT5DLjwYsmd-kK_Ga9HBMTmYITCA",
  authDomain: "gingerprime-77962.firebaseapp.com",
  databaseURL: "https://gingerprime-77962.firebaseio.com",
  projectId: "gingerprime-77962",
  storageBucket: "gingerprime-77962.appspot.com",
  messagingSenderId: "639092126320",
  appId: "1:639092126320:web:813f5b6d68860360bcfab5",
  measurementId: "G-J65MVSCPVZ"
};

  firebase.initializeApp(firebaseConfig);

  const projectFirestore = firebase.firestore();
  const projectAuth = firebase.auth();
  const projectStorage = firebase.storage();

  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export { projectFirestore, projectAuth, projectStorage, timestamp }

